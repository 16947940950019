export interface IretStatus {
  status: string;
  msg?: string;
}

export interface IresData {
  code: string;
  msg?: string;
  result?: any;
}

export interface IresData {
  code: string;
  msg?: string;
  result?: any;
}

export interface IbreadcrumbItem {
  name: string;
  path: string;
}

// 1.培训通知 2.会议论坛 3.会员简介 4.会员风采 5.团体标准 6.参考资料 7.会员资讯
export enum Estata {
  'consult' = 7,
  'cultivate' = 1,
  'meeting' = 2,
  'memberintroduction' = 3,
  'membermien' = 4,
  'groupstandards' = 5,
  'referencedata' = 6,
}

export const ElistText: any = {
  consult: '会员资讯',
  cultivate: '培训通知',
  meeting: '会议论坛',
  memberintroduction: '会员简介',
  membermien: '会员风采',
  groupstandards: '团体标准',
  referencedata: '参考资料',
};

export const memberState: any = {
  1: '待确认',
  3: '已拒绝',
  4: '退回修改',
  5: '再次确认',
  };